import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Login from '../views/user/Login.vue'
import Skeleton from '@/views/Skeleton.vue'

import store from '@/store'


const routes: Array<RouteRecordRaw> = [
	{
		path: '/',
		name: 'Skeleton',
		component: Skeleton,
		props: { mainView: 'UserHome' },
		meta: {
			requiresAuth: true
		}
	},
	{
		path: '/monitoring',
		name: 'Monitoring',
		component: Skeleton,
		props: { mainView: 'Monitoring' },
		meta: {
			requiresAuth: true
		}
	},
	{
		path: '/request',
		name: 'RequestPage',
		component: Skeleton,
		props: { mainView: 'RequestPage' },
		meta: {
			requiresAuth: true
		}
	},
	{
		path: '/create-user',
		name: 'CreateUser',
		component: Skeleton,
		props: { mainView: 'CreateUser' },
		meta: {
			requiresAuth: true
		}
	},
	{
		path: '/constructor',
		name: 'Constructor',
		component: Skeleton,
		props: { mainView: 'ConstructorPage' },
		meta: {
			requiresAuth: true
		}
	},
	{
		path: '/login',
		name: 'Login',
		component: Login,
		meta: {
			title: 'Home Page - Example App'
		}
	}
]

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes
})

router.beforeEach((to) => {
	if (to.meta.requiresAuth && !store.getters['user/isLoggedIn']) {
		return {
			path: '/login',
			query: { redirect: to.fullPath }
		}
	}
})

export default router
