import { Module } from 'vuex-smart-module'
import StatusBarGetters from './getters'
import StatusBarState from './state'
import StatusBarMutations from './mutations'
import StatusBarActions from './actions'

const StatusBar = new Module({
  state: StatusBarState,
  getters: StatusBarGetters,
  mutations: StatusBarMutations,
  actions: StatusBarActions
})

export default StatusBar