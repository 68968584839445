import { Getters } from 'vuex-smart-module'
import UserState from './state'

export default class UseGetters extends Getters<UserState> {
  /**
   * Параметризированный greeting, не кэшируется Vuex
   * @param name
   * @example module.getters.greeting("Habr!")
   */
  // greeting(name: string): string {
  //   return this.state.auth_success + ', ' + name
  // }

  /**
   * Не параметризированный greeting, кэшируется Vuex
   * @example this.$store.getters['user/isLoggedIn']
   */
  get isLoggedIn(): boolean {
    return this.state.access_token ? true : false
  }
}