import {User} from './interface'

export default class UserState {
  access_token: string = localStorage.getItem('access_token') || ''
  token_type: string = ""
  token_date: string | null = null
  expires_in: number | null = null
  webSocketData:any = null
  info: User = {
  }
  users:any = null
  usersLoading:boolean = false
}