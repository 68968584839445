import { Module, createStore } from 'vuex-smart-module'
import skeleton from './skeleton'
import user from './user'
import statusBar from './status-bar'
import constructorPage from './constructor'
import monitoring from './monitoring'
import engineer from './engineer'



const root = new Module({
  modules: {
    skeleton,
    user,
    statusBar,
    constructorPage,
    monitoring,
    engineer
  },
})

const store = createStore(root)

export default store

export const userModule = user.context(store)