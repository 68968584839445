import { Module } from 'vuex-smart-module'
import MonitoringGetters from './getters'
import MonitoringState from './state'
import MonitoringMutations from './mutations'
import MonitoringActions from './actions'

const Monitoring = new Module({
  state: MonitoringState,
  getters: MonitoringGetters,
  mutations: MonitoringMutations,
  actions: MonitoringActions
})

export default Monitoring