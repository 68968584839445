import { Installations } from './interface';
import { IDS_S } from './interface';
import { Products } from './interface';
import { ErrorInstalation } from './interface';
import { RightItemDemension } from './interface';

  export default class ConstructorState {
  selectCompany:any={}
  installations:Array<Installations>=[]
  selectorCompanyOption:Array<{name:string, value:string}>=[]
  IDS_s:Array<IDS_S>=[]
  products:Array<Products>=[]
  errorInstallations:Array<ErrorInstalation>=[]
  rightItemDemension:Array<RightItemDemension> = []
  uuidInstalationFilter:string = ''
  IDS_sLoading=false
  installationsLoading=false
}