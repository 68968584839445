import { Mutations } from 'vuex-smart-module'
import MonitoringState from './state'

export default class MonitoringMutations extends Mutations<MonitoringState> {
	setElements(element: any){
		this.state.elements.push(element)
	}
	setAllElements(elements: any){
		this.state.elements = elements
	}
	changeInDesktop({serverIndex,bool }:any){
		this.state.activeInstallation[serverIndex].inDesktop = bool
	}
	setActiveInstallation(response:any){
		this.state.activeInstallation = response
	}
	setActiveInstallationState({i,state}:any){
		this.state.activeInstallation[i].state = state
	}
	changeLabel( { elemntIndex, label }:any){
		this.state.elements[elemntIndex].label = label
	}
	deleteElement(indexElement:number){
		for (let i = 0; i < this.state.elements.length; i++) {
			const el = this.state.elements[i];
			if(el.target){
				if ((el.target == this.state.elements[indexElement].id) || (el.source == this.state.elements[indexElement].id)){
					this.state.elements.splice(i,100)
				}
			}
		}
		this.state.elements.splice(indexElement,1)
	}
	setActiveInstallationsLoading(bool: boolean){
		this.state.activeInstallationsLoading = bool
	}
}
