import axios from "axios"
const uri_server = process.env.VUE_APP_URI_SERVER

export default {
	post(url: string, data: any, headers?: any) {
		return axios.post(uri_server+"/api/"+url, data, headers)
	},

	getHeaders() {
		return {
			'Content-Type': 'application/json',
			'Authorization': "Bearer "+localStorage.getItem('access_token')
		}
	},


	graphql(query: string, variables?: any) {
		return axios.post(uri_server+'/graphql', {
			query: query,
			variables: variables
		},
		{
			headers: this.getHeaders()
		})
	}
}