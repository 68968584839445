import { Mutations } from 'vuex-smart-module'
import ConstructorState from './state'
import { Installations } from './interface';
import { IDS_S } from './interface';
import { Products } from './interface';
import { ErrorInstalation } from './interface';
import { RightItemDemension } from './interface';

export default class ConstructorMutations extends Mutations<ConstructorState> {
  setRightItemDemension(item:Array<RightItemDemension>){
    this.state.rightItemDemension = item
  }
  setSelectCompany(selectCompany:{name:string, value:string}){
    this.state.selectCompany = selectCompany
  }
  setUuidInstalationFilter(filter:string){
    this.state.uuidInstalationFilter = filter
  }
  setSelectorCompanyOption(selectCompany:Array<{name:string, value:string}>){
    this.state.selectorCompanyOption = selectCompany
  }
  setProducts(response:Array<Products>){
    this.state.products = response
  }
  setInstallations(response:Array<Installations>){
    this.state.installations = response
  }
  setIDS_s(response:Array<IDS_S>){
    this.state.IDS_s = response
  }
  setErrorInstallations({error, installationId}:ErrorInstalation){
    if (this.state.errorInstallations.length){
      for(let i=0; i<this.state.errorInstallations.length; i++){
        if (this.state.errorInstallations[i].installationId == installationId){
          this.state.errorInstallations[i] = {error:error, installationId:installationId}
          return
        }
      }
    }
    this.state.errorInstallations.push({error:error, installationId:installationId})
  }
  setIDS_sLoading(bool: boolean){
    this.state.IDS_sLoading = bool
  }
  setInstallationsLoading(bool: boolean){
    this.state.installationsLoading = bool
  }
}