import { Actions } from 'vuex-smart-module'

import StatusBarState from './state'
import StatusBarGetters from './getters'
import StatusBarMutations from './mutations'
import {Logs} from './interface'
import moment from 'moment'
export default class StatusBarActions extends Actions<
	StatusBarState,
	StatusBarGetters,
	StatusBarMutations,
	StatusBarActions
> {
	createLog(data: {
		status: string,
		discription: string,
		important: string
	}){
		if (this.state.logs.length>98) {
			this.commit('delete_last_log')
		}
		let logsDate:string = moment().format('YYYY-MM-DD HH:mm:ss')
		let finalData:Logs={
			status:data.status,
			discription:data.discription,
			important:data.important,
			logsDate: logsDate
		}
		this.commit('save_data_logs',finalData)
		let mostImportant:Logs = this.state.logs.sort((a, b) => a.important > b.important ? 1 : -1)[0]
		this.commit('save_most_important_logs',mostImportant)
	}
}