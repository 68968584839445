import { Actions } from 'vuex-smart-module'
import { User } from './interface'

import UserState from './state'
import UserGetters from './getters'
import UserMutations from './mutations'
import Api from '@/services/Api'
import store from '@/store'


export default class UserActions extends Actions<
	UserState,
	UserGetters,
	UserMutations,
	UserActions
> {

	// Запись данных авторизации
	accessLogin(data: {
		access_token: string,
		token_type: string,
		expires_in: number
	}) {
		localStorage.setItem('access_token', data.access_token)
		this.commit('auth_success', data)
	}

	// запись данных пользователя
	saveDataMe(data: User) {
		this.commit('save_data_me', data)
	}

	logout() {
		this.commit('logout')
		localStorage.removeItem('access_token')
	}
	async refresh() {
		await Api.graphql(
			`mutation {
				refreshToken {
						access_token
						expires_in
						token_type
				} 
			}`
		).then((response:any) => {
			if(response.data.errors.length){
				store.dispatch('user/logout')
			}else this.dispatch('accessLogin',response.data.data.refreshToken)
		})
		.catch(()=> {
			store.dispatch('user/logout')
		})
	}
	async getUsers() {
		await Api.graphql(
			`{
				users( hasCompanies: { 
					column: ID,
					value: ${store.state.constructorPage.selectCompany.value} 
				}) {
					data {
						id
						name
						email
							roles {
								id
								name
							}
						}
				}
			}`
		).then((response:any) => {
			if(response.data.data.users.data){
				this.commit('setUsers',response.data.data.users.data )
			}
		})
		this.commit('setUsersLoading', false)
	}
}