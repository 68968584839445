import { Actions } from 'vuex-smart-module'

import MonitoringState from './state'
import MonitoringGetters from './getters'
import MonitoringMutations from './mutations'
import Api from '@/services/Api'
import store from '@/store'
import moment from 'moment'

export default class MonitoringActions extends Actions<
	MonitoringState,
	MonitoringGetters,
	MonitoringMutations,
	MonitoringActions
> {
	async getActiveInstallations() {
		if(store.state.constructorPage.selectCompany.value){
			await Api.graphql(`{
				installations(
					company_id: ${store.state.constructorPage.selectCompany.value}
					status: activated
					) {
					paginatorInfo {
						total
						hasMorePages
					},
					data {
						id
						name
						location
						place
						status
						utc_activation
						utc_license
						utc_support
						utc_guarantee
					}
				}
				}`)
			.then(response =>{
				store.dispatch('statusBar/createLog', {
					status: response.status,
					discription: 'Получение активированных сборок',
					important: 1
				})
				if(response.data.data.installations.paginatorInfo.total>0){
					let installations = response.data.data.installations.data
					if (this.state.elements.length){
						let elementsId = []
						for (let i = 0; i < this.state.elements.length; i++) {
							if(this.state.elements[i].type == "customServer"){
								elementsId.push(this.state.elements[i].id)
							}
						}
						let get = false
						for (let i = 0; i < installations.length; i++) {
							for (let k = 0; k < elementsId.length; k++) {
								if(elementsId[k].slice(3) == installations[i].id){
									installations[i].inDesktop = true
									get = true
									k=elementsId.length+1
								}
								if(!get){
									installations[i].inDesktop = false
								}
							}
						}
					}else{
						for (let i = 0; i < installations.length; i++) {
							installations[i].inDesktop = false
						}
					}
					this.commit('setActiveInstallation', installations)
				}else this.commit('setActiveInstallation', [])
			})
			.catch((err)=>
				store.dispatch('statusBar/createLog', {
					status: err.status,
					discription: 'Ошибка получения активированных сборок',
					important: 1
				})
			)
		}
		this.commit('setActiveInstallationsLoading', false)
	}
	async saveElements(){
		let elements = JSON.stringify(store.state.monitoring.elements).replaceAll('"', '\\"')
		await Api.graphql(
			`mutation {
					upsertScheme  (
					input:
						{
							company_id: ${store.state.constructorPage.selectCompany.value}
							json: "${elements}"
						}
				) {
					company_id
				}
			}`
		)
	}
	InstallationState(){
		for (let i = 0; i < this.state.activeInstallation.length; i++) {
			const activeInstallation = this.state.activeInstallation[i];
			if(store.state.user.webSocketData){
				if(store.state.user.webSocketData.data.installation_id==activeInstallation.id){
					if (store.state.user.webSocketData.data.state != 'good'){
						this.commit('setActiveInstallationState', {i:i,state:'bad'})
						continue
					}
				}else {
					this.commit('setActiveInstallationState', {i:i,state:'notConected'})
					continue
				}
			}else {
				this.commit('setActiveInstallationState', {i:i,state:'notConected'})
				continue
			}
			if(activeInstallation.utc_license){
				let duration = moment.duration(moment(activeInstallation.utc_license, 'YYYY-MM-DD HH:mm:ss').diff( moment(new Date()) ))
				let days = duration.asDays()
				if (days < 7) {
					this.commit('setActiveInstallationState', {i:i,state:'bad'})
					continue
				}
			}else{
				this.commit('setActiveInstallationState', {i:i,state:'bad'})
				continue
			}
			if(activeInstallation.utc_guarantee){
				let duration = moment.duration(moment(activeInstallation.utc_guarantee, 'YYYY-MM-DD HH:mm:ss').diff( moment(new Date()) ))
				let days = duration.asDays()
				if (days < 7) {
					this.commit('setActiveInstallationState', {i:i,state:'bad'})
					continue
				}
			}else{
				this.commit('setActiveInstallationState', {i:i,state:'bad'})
				continue
			}
			if(activeInstallation.utc_support){
				let duration = moment.duration(moment(activeInstallation.utc_support, 'YYYY-MM-DD HH:mm:ss').diff( moment(new Date()) ))
				let days = duration.asDays()
				if (days < 7) {
					this.commit('setActiveInstallationState', {i:i,state:'bad'})
					continue
				}
			}else{
				this.commit('setActiveInstallationState', {i:i,state:'bad'})
				continue
			}
			this.commit('setActiveInstallationState', {i:i,state:'good'})
			continue
		}
	}
	async getSchemeJson(){
		await Api.graphql(
			`{
				scheme(company_id: ${store.state.constructorPage.selectCompany.value}) {
					json
				}
			}`
		).then((response)=> {
			store.dispatch('statusBar/createLog', {
				status: response.status,
				discription: 'Получение схемы',
				important: 1
			})
			if(response.data.data.scheme){
				if(response.data.data.scheme.json){
					this.commit('setAllElements', JSON.parse(response.data.data.scheme.json))
				}else this.commit('setAllElements', [])
			}else this.commit('setAllElements', [])
		})
		.catch((err)=>{
			store.dispatch('statusBar/createLog', {
				status: err.status,
				discription: 'Ошибка получение схемы',
				important: 1
			})
		})
	}
}