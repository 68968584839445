import store from '@/store'
import API from '@/services/Api'
import router from '@/router'
export default function () {
    async function me(callTime?:number) {
        let query =`{
            me {
                id
                uuid
                name
                email
                created_at
                updated_at
                companies {
                    id
                    uuid
                    created_at
                    updated_at
                    name
                    short_name
                    country_id
                    email
                    phone
                    mailing_address
                    actual_address
                    legal_address
                    inn
                    agent
                    short_text
                    text
            }
        }
    }`

    await API.graphql(query)
    .then( async (response) => {
        if (response.data.data.me){
            store.dispatch('user/saveDataMe', response.data.data.me)
            store.dispatch('statusBar/createLog', {
                status: response.status,
                discription: 'Получение информации о пользователе',
                important: 1
            })
            await store.dispatch('engineer/getRoles')
            if (response.data.data.me.companies.length){
                let companyOptions = []
                for (let i = 0; i < response.data.data.me.companies.length; i++) {
                    companyOptions.push({
                        name:response.data.data.me.companies[i].name,
                        value:response.data.data.me.companies[i].id
                    })
                }
                store.commit('constructorPage/setSelectorCompanyOption', companyOptions)
                if(router.currentRoute.value.query.company){
                    let getValue = false
                    for (let i = 0; i < store.state.constructorPage.selectorCompanyOption.length; i++) {
                        const element = store.state.constructorPage.selectorCompanyOption[i]
                        if(element.value == router.currentRoute.value.query.company){
                            store.commit('constructorPage/setSelectCompany', element)
                            getValue = true
                            i = store.state.constructorPage.selectorCompanyOption.length +1
                        }
                    }
                    if (!getValue) store.commit('constructorPage/setSelectCompany', companyOptions[0])
                }else store.commit('constructorPage/setSelectCompany', companyOptions[0])
            }
            if(store.state.engineer.roleAndPermision.roles[0].name =='tech_support'){
                await store.dispatch('engineer/getAllCompanies')
            }
            await store.dispatch('engineer/getRequests')
            if(store.state.constructorPage.installations.length){
                store.dispatch('constructorPage/getDemension')
                for (let i = 0; i < store.state.constructorPage.installations.length; i++) {
                    store.dispatch('constructorPage/checkErorrs', i)
                }
            }
            await store.dispatch('monitoring/InstallationState')
            if(store.state.monitoring.activeInstallation.length){
                setInterval(async()=>{
                    await store.dispatch('monitoring/InstallationState')
                }, 3000)
            }
            let uri = 'wss://clients.softlab.tv/ws';
            let ws = new WebSocket(uri)
            ws.onopen = function() {
                store.dispatch('statusBar/createLog', {
                    status: 0,
                    discription: 'Подключение к веб-сокету',
                    important: 1
                })
            }
            ws.onmessage = (evt)=>{
                store.commit('user/setWebSocketData', JSON.parse(evt.data))
            }
        }else{
            if(callTime==2) {
                store.dispatch('statusBar/createLog', {
                    status: response.status,
                    discription: 'Ошибка получения данных пользователя',
                    important: 1
                })
                await store.dispatch('user/logout')
                return
            }
            await store.dispatch('user/refresh')
            me(2)
            return
        }
    })
    .catch((err) => {
        store.dispatch('statusBar/createLog', {
            status: err.status,
            discription: 'Ошибка получения данных пользователя',
            important: 1
        })
    })
    }
    return {
        me
    }
}
