import { Mutations } from 'vuex-smart-module'
import UserState from './state'
import {User} from './interface'

export default class UserMutations extends Mutations<UserState> {
  auth_success(data: { access_token: string } ) {
    this.state.access_token = data.access_token
  }
  save_data_me(data: User ) {
    this.state.info = data
  }
  setWebSocketData(data:any){
    this.state.webSocketData = data
  }
  logout() {
    window.location.href = '/login'
    this.state.access_token = ''
  }
  setUsers(users:any){
    this.state.users = users
  }
  setUsersLoading(bool:boolean){
    this.state.usersLoading = bool
  }
}