import { Actions } from 'vuex-smart-module'
import SkeletonState from './state'
import SkeletonGetters from './getters'
import SkeletonMutations from './mutations'
export default class SkeletonActions extends Actions<
  SkeletonState,
  SkeletonGetters,
  SkeletonMutations,
  SkeletonActions
> {
  togStatusTop() {
    this.commit('togStatusTop')
  }
}