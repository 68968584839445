import { Actions } from 'vuex-smart-module'

import ConstructorState from './state'
import ConstructorGetters from './getters'
import ConstructorMutations from './mutations'
import Api from '@/services/Api'
import store from '@/store'


export default class ConstructorActions extends Actions<
	ConstructorState,
	ConstructorGetters,
	ConstructorMutations,
	ConstructorActions
> {
	getDemension(){
		let elem =  document.querySelectorAll('.right__server')
		let rightItemDemension:any = []
		elem.forEach(el => rightItemDemension.push({
			left: el.getBoundingClientRect().left,
			right: el.getBoundingClientRect().right,
			top: el.getBoundingClientRect().top,
			bottom: el.getBoundingClientRect().bottom,
			}))
		this.commit('setRightItemDemension', rightItemDemension)
	}
	async getProducts() {
		await Api.graphql(`{
			product_types {
				id
				created_at
				updated_at
				name
				uuid
				rules {
					id
					created_at
					updated_at
					one_id
					second_id
					rule
				}
			}
		}`)
		.then(response => {
			store.dispatch('statusBar/createLog', {
				status: response.status,
				discription: 'Получение продуктов',
				important: 1
			})
			this.commit('setProducts', response.data.data.product_types)
		})
		.catch((err)=>{
			store.dispatch('statusBar/createLog', {
				status: err.status,
				discription: ' Ошибка получение продуктов',
				important: 1
			})
		})
	}
	async getInstallations(companyValue:any) {
		if(this.state.selectCompany.value || companyValue){
			await Api.graphql(`{
				installations(
					company_id: ${companyValue ? companyValue: this.state.selectCompany.value}
					) {
					paginatorInfo {
						total
						hasMorePages
					},
					data {
						id
						uuid
						name
						location
						place
						status
					}
				}
			}`)
			.then(response =>{
				store.dispatch('statusBar/createLog', {
					status: response.status,
					discription: 'Получение сборок',
					important: 1
				})
				if(response.data.data.installations){
					if(response.data.data.installations.paginatorInfo.total>0){
						let installations = response.data.data.installations.data
						installations.sort((a:any,b:any)=>b.id-a.id)
						this.commit('setInstallations', installations)
					}else this.commit('setInstallations', [])
				}
			})
			.catch((err)=>{
				store.dispatch('statusBar/createLog', {
					status: err.status,
					discription: 'Ошибка получения сборок',
					important: 1
				})
			})
		}
		this.commit('setInstallationsLoading', false)
	}
	async getIDS_s() {
		if(this.state.selectCompany.value){
			await Api.graphql(`{
				my_ids_s(company_id: ${this.state.selectCompany.value}) {
					paginatorInfo {
						total
						hasMorePages
					},
					data {
						id
						product_name
						installation_id
						product_type_id
						custom
						demo
						product_type{
							name
						}
					}
				}
			}`)
			.then(response =>{
				store.dispatch('statusBar/createLog', {
					status: response.status,
					discription: 'Получение ids',
					important: 1
				})
				if(response.data.data.my_ids_s.paginatorInfo.total>0){
					this.commit('setIDS_s', response.data.data.my_ids_s.data)
				}else this.commit('setIDS_s', [])
			})
			.catch((err)=>{
				store.dispatch('statusBar/createLog', {
					status: err.status,
					discription: 'Ошибка олучения ids',
					important: 1
				})
			})
		}
		this.commit('setIDS_sLoading', false)
	}
	checkErorrs(i:number){
		this.commit('setErrorInstallations', {error:'', installationId:i})
		if(this.state.IDS_s.length && this.state.products.length){
			let arrayIdsTypes=[]
			for (let indexIds = 0; indexIds < this.state.IDS_s.length; indexIds++) {
				if (this.state.IDS_s[indexIds].installation_id == this.state.installations[i].id){
					if(!(this.state.IDS_s[indexIds].product_type)) continue
					arrayIdsTypes.push(this.state.IDS_s[indexIds].product_type.name=='Сервер' ? '1' : this.state.IDS_s[indexIds].product_type.name=='Плата' ? '2' : '3')
				}
			}
			if(!arrayIdsTypes.includes('1')) this.commit('setErrorInstallations', {error:'Добавте сервер', installationId:i})
			for (let indexProduct = 0; indexProduct < this.state.products.length; indexProduct++) {
					let product = this.state.products[indexProduct]
				for (let indexRules = 0; indexRules < product.rules.length; indexRules++) {
					if (product.rules[indexRules].rule == "forbidden"){
						if (arrayIdsTypes.length>1){
							if (product.rules[indexRules].one_id == product.rules[indexRules].second_id){
								if(arrayIdsTypes.filter(e=>e==product.rules[indexRules].one_id).length>1){
									this.commit('setErrorInstallations', {error:`${product.name}: Запрещен повтор`, installationId:i})
									return
								}
							}else{
								if(arrayIdsTypes.includes(product.rules[indexRules].one_id) && arrayIdsTypes.includes(product.rules[indexRules].second_id)){
									this.commit('setErrorInstallations', {error:`${product.name}: Запрещена связка ${this.state.products[product.rules[indexRules].one_id-1].name} и ${this.state.products[product.rules[indexRules].second_id-1].name}`, installationId:i})
									return
								} 
							}
						}
					}else if(product.rules[indexRules].rule == "must"){
						if (product.rules[indexRules].one_id == product.rules[indexRules].second_id){
							if(arrayIdsTypes.filter(e=>e==product.rules[indexRules].one_id).length==1){
								this.commit('setErrorInstallations', {error:`${product.name}: добавте ${product.name}`, installationId:i})
								return
							}
						}else{
							if((!arrayIdsTypes.includes(product.rules[indexRules].one_id) && arrayIdsTypes.includes(product.rules[indexRules].second_id))){
								this.commit('setErrorInstallations', {error:`${product.name}: добавте ${this.state.products[product.rules[indexRules].one_id-1].name}`, installationId:i})
								return
							}else if ((!(arrayIdsTypes.includes(product.rules[indexRules].second_id)) && arrayIdsTypes.includes(product.rules[indexRules].one_id))){
								this.commit('setErrorInstallations', {error:`${product.name}: добавте ${this.state.products[product.rules[indexRules].second_id-1].name}`, installationId:i})
								return
							}   
						}
					}
				}
			}
		}
	}
}