import { Mutations } from 'vuex-smart-module'
import StatusBarState from './state'
import {Logs} from './interface'


export default class StatusBarMutations extends Mutations<StatusBarState> {
  save_data_logs(data: Logs ) {
    this.state.logs.push(data)
  }
  delete_last_log(){
    this.state.logs.shift()
  }
  save_most_important_logs(data: Logs){
    this.state.importantLog = data
  }
}