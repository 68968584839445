import { Module } from 'vuex-smart-module'
import EngineerGetters from './getters'
import EngineerState from './state'
import EngineerMutations from './mutations'
import EngineerActions from './actions'

const Engineer = new Module({
  state: EngineerState,
  getters: EngineerGetters,
  mutations: EngineerMutations,
  actions: EngineerActions
})

export default Engineer