import { Module } from 'vuex-smart-module'
import ConstructorGetters from './getters'
import ConstructorState from './state'
import ConstructorMutations from './mutations'
import ConstructorActions from './actions'

const constructor = new Module({
  state: ConstructorState,
  getters: ConstructorGetters,
  mutations: ConstructorMutations,
  actions: ConstructorActions
})

export default constructor