
import { defineComponent } from 'vue'
import Modal from './Modal.vue';
import { mapState } from 'vuex';

export default defineComponent({

  name: 'statusbar-block',
  components: { Modal },
    computed: {
    ...mapState([
      'statusBar'
    ])
  },
  methods: {
    openStatusBar(){
      this.modalActive = true
    }
  },
  data() {
    return {
      modalActive: false,
    }
  },
})
