import { Mutations } from 'vuex-smart-module'
import EngineerState from './state'

export default class EngineerMutations extends Mutations<EngineerState> {
    setRoleAndPermision(roleAndPermision:any){
        this.state.roleAndPermision = roleAndPermision
    }
    setSelectAllCompanies(selectCompany:{name:string, value:string}){
        this.state.selectAllCompanies = selectCompany
    }
    setSelectorAllCompaniesOption(selectCompany:Array<{name:string, value:string}>){
        this.state.selectorAllCompaniesOption = selectCompany
    }
    setAllIDS_s(response:any){
        this.state.AllIDS_s = response
    }
    setRequests(response:any){
        this.state.requests = response
    }
}