import { Actions } from 'vuex-smart-module'

import EngineerState from './state'
import EngineerGetters from './getters'
import EngineerMutations from './mutations'
import API from '@/services/Api'
import store from '@/store'
import router from '@/router'


export default class EngineerActions extends Actions<
	EngineerState,
	EngineerGetters,
	EngineerMutations,
	EngineerActions
> {
	async getRoles() {
		await API.graphql(
			`{
				me {
					id
					roles {
						id
						name
					}
					permissions {
						id
						name
						guard_name
					}
				}
			}`)
			.then((response)=>{
				this.commit('setRoleAndPermision', response.data.data.me)
				store.dispatch('statusBar/createLog', {
					status: response.status,
					discription: 'Получение роли',
					important: 1
				})
			})
			.catch((err)=>{
				store.dispatch('statusBar/createLog', {
					status: err.status,
					discription: 'Ошибка получения роли',
					important: 1
				})
			})
	}
	async getAllIDS_s() {
		if(store.state.constructorPage.selectCompany.value){
			await API.graphql(`{
				ids_s(company_id: ${store.state.constructorPage.selectCompany.value}) {
					paginatorInfo {
						total
						hasMorePages
					},
					data {
						id
						product_name
						installation_id
						product_type_id
						custom
						demo
						product_type{
							name
						}
					}
				}
			}`)
			.then(response =>{
				store.dispatch('statusBar/createLog', {
					status: response.status,
					discription: 'Получение ids',
					important: 1
				})
				if(response.data.data.ids_s.paginatorInfo.total>0){
					store.commit('constructorPage/setIDS_s', response.data.data.ids_s.data)
					this.commit('setAllIDS_s', response.data.data.ids_s.data)
				} else store.commit('constructorPage/setIDS_s', [])
			})
			.catch((err)=>{
				store.dispatch('statusBar/createLog', {
					status: err.status,
					discription: 'Ошибка получения ids',
					important: 1
				})
			})
			store.commit('constructorPage/setIDS_sLoading', false)
		}
	}
	async getEnginerInstallations() {
		if(store.state.constructorPage.selectCompany.value){
			await API.graphql(`{
				installations(company_id: ${store.state.constructorPage.selectCompany.value}) {
					paginatorInfo {
						total
						hasMorePages
					},
					data {
						id
						name
						location
						place
						status
					}
				}
			}`)
			.then(response =>{
				store.dispatch('statusBar/createLog', {
					status: response.status,
					discription: 'Получение сборок',
					important: 1
				})
				if(response.data.data.installations.paginatorInfo.total>0){
					let installations = response.data.data.installations.data
					installations.sort((a:any,b:any)=>a.id-b.id)
					store.commit('constructorPage/setInstallations', installations)
				}else store.commit('constructorPage/setInstallations', [])
			})
			.catch((err)=>{
				store.dispatch('statusBar/createLog', {
					status: err.status,
					discription: 'Ошибка получения сборок',
					important: 1
				})
			})
		}
	}
	async getAllCompanies(){
		await API.graphql(
			`{
				companies {
					paginatorInfo {
						total
						hasMorePages
					},
					data {
						name
						id
					}
				}
			}`
		).then((response)=> {
			if(response.data.data.companies.data){
				store.dispatch('statusBar/createLog', {
					status: response.status,
					discription: 'Получение компаний',
					important: 1
				})
				let companyOptions = []
				for (let i = 0; i < response.data.data.companies.data.length; i++) {
					companyOptions.push({
						name:response.data.data.companies.data[i].name,
						value:response.data.data.companies.data[i].id
					})
				}
				this.commit('setSelectorAllCompaniesOption', companyOptions)
				if(router.currentRoute.value.query.company){
					let getValue = false
					for (let i = 0; i < companyOptions.length; i++) {
						const element = companyOptions[i]
						if(element.value == router.currentRoute.value.query.company){
							store.commit('constructorPage/setSelectCompany', element)
							getValue = true
							i = companyOptions.length +1
						}
					}
					if (!getValue) store.commit('constructorPage/setSelectCompany', companyOptions[0])
				}else store.commit('constructorPage/setSelectCompany', companyOptions[0])
			}
		})
		.catch((err)=>{
			store.dispatch('statusBar/createLog', {
				status: err.status,
				discription: 'Ошибка получения компаний',
				important: 1
			})
		})
	}
	async getRequests() {
		if(this.state.roleAndPermision.roles[0].name =='tech_support'){
			await API.graphql(
				`{
					requests {
						paginatorInfo {
						total
					},
						data {
							id
							uuid
							created_at
							updated_at
							company_id
							type_id
							installation_id
							title
							text
							status
							company {
								name
							}
						}
					}
				}`)
			.then(response =>{
				store.dispatch('statusBar/createLog', {
					status: response.status,
					discription: 'Получение заявок',
					important: 1
				})
				if(response  && response.data.data.requests){
					if(response.data.data.requests.data){
						if(response.data.data.requests.paginatorInfo.total>0){
							this.commit('setRequests', response.data.data.requests.data)
						}else this.commit('setRequests', [])
					}else this.commit('setRequests', [])
				}
			})
			.catch((err)=>
				store.dispatch('statusBar/createLog', {
					status: err.status,
					discription: 'Ошибка получения заявок',
					important: 1
				})
			)
		}else{
			await API.graphql(
				`{
					requests(company_id: ${store.state.constructorPage.selectCompany.value}) {
						paginatorInfo {
						total
					},
						data {
							id
							uuid
							created_at
							updated_at
							company_id
							type_id
							installation_id
							title
							text
							status
							company {
								name
							}
						}
					}
				}`)
			.then(response =>{
				store.dispatch('statusBar/createLog', {
					status: response.status,
					discription: 'Получение заявок',
					important: 1
				})
				if(response  && response.data.data.requests){
					if(response.data.data.requests.data){
						if(response.data.data.requests.paginatorInfo.total>0){
							this.commit('setRequests', response.data.data.requests.data)
						}else this.commit('setRequests', [])
					}else this.commit('setRequests', [])
				}
			})
			.catch((err)=>
				store.dispatch('statusBar/createLog', {
					status: err.status,
					discription: 'Ошибка получения заявок',
					important: 1
				})
			)
		}
	}
}