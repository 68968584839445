import { Module } from 'vuex-smart-module'
import SkeletonGetters from './getters'
import SkeletonState from './state'
import SkeletonMutations from './mutations'
import SkeletonActions from './actions'

const skeleton = new Module({
  state: SkeletonState,
  getters: SkeletonGetters,
  mutations: SkeletonMutations,
  actions: SkeletonActions
})

export default skeleton